import './css/styles.css';
import React from 'react';
import { Row, Col, Container } from 'reactstrap';

import Lab from './assets/images/A-propos/Lab.jpg';
import Ar2 from './assets/images/A-propos/ar-2.jpg';
import ProE600Top from './assets/images/A-propos/Pro-E600-Top.jpg';
import ProE600Cab from './assets/images/A-propos/Pro-E600-CAB.jpg';
import Iris from './assets/images/Logo/Partenaire/IRIS.png';
import SOI from './assets/images/Logo/Partenaire/SOI-EN.png';
import OptoReseau from './assets/images/Logo/Partenaire/Opto-reseau.jpeg';
import RegardAction from './assets/images/Logo/Partenaire/RA.jpeg';
import Progressif from './assets/images/Services/Progressif.jpg';
import Bifocal from './assets/images/Services/Bifocal-05.jpg';
import VerresStock from './assets/images/Services/Verres Stock-06.jpg';
import ClipSolaire from './assets/images/Services/Clip_Solaire_sur_Mesure-02.jpg';
import Reparations from './assets/images/Services/Réparations-04.jpg';

const Home = () => {
  return (
      <>
        <main>
          <section className="intro">
            <h1>Bienvenue chez O'Lab Plus</h1>
            <p>
              Laboratoire familial indépendant au service des professionnels de la
              vue depuis 1987
            </p>
          </section>

          <section className="info-section">
            <div className="info-box">
              <h3>Inscription gratuite</h3>
              <p>
                Créez votre compte gratuitement avec votre nom, courriel et mot de
                passe.
              </p>
            </div>
            <div className="info-box">
              <h3>Commandez en ligne</h3>
              <p>
                Faites vos achats en toute sécurité avec nos produits de haute
                qualité.
              </p>
            </div>
            <div className="info-box">
              <h3>Suivi des commandes</h3>
              <p>Suivez vos commandes et consultez votre historique d'achats.</p>
            </div>
          </section>

          <section id="a-propos">
            <h2>À Propos de Nous</h2>
            <div className="a-propos-content">
              <div className="a-propos-text">
                <p>
                  O'Lab Plus inc. est votre partenaire de confiance pour la
                  conception de verres ophtalmiques sur mesure. Spécialisés dans les
                  prescriptions optiques complexes, nous fournissons des solutions
                  de haute précision adaptées aux exigences les plus élevées.
                </p>

                <p>
                  Grâce à notre expertise et à nos technologies de pointe, nous
                  garantissons des verres de qualité optimale pour une vision nette
                  et confortable pour vos patients.
                </p>
              </div>
              <div className="a-propos-images">
                <img src={Lab} alt="Laboratoire optique" />
                <img src={Ar2} alt="Fabrication de verres" />
                <img src={ProE600Top} alt="Équipement de haute précision" />
                <img src={ProE600Cab} alt="Expert en optique au travail" />
              </div>
            </div>
          </section>

          <section id="nos-services">
            <h2>Nos Services</h2>
            <div className="services-grid">
              <div className="service-card">
                <img src={Progressif} alt="Progressif" />
                <h3>Progressif</h3>
              </div>
              <div className="service-card">
                <img src={Bifocal} alt="Bifocal" />
                <h3>Bifocaux</h3>
              </div>
              <div className="service-card">
                <img src={VerresStock} alt="Verres Stock" />
                <h3>Verres Stock</h3>
              </div>
              <div className="service-card">
                <img src={ClipSolaire} alt="Clip Solaire sur Mesure" />
                <h3>Clip Solaire sur Mesure</h3>
              </div>
              <div className="service-card">
                <img src={Reparations} alt="Réparations" />
                <h3>Réparations</h3>
              </div>
            </div>
            <section id="pourquoi-choisir">
              <h2>Pourquoi choisir O’lab Plus inc. ?</h2>
              <ul>
                <li>
                  <span className="checkmark"></span>{' '}
                  <strong>Précision et qualité</strong> : Des technologies avancées
                  pour des verres parfaitement adaptés aux besoins spécifiques de
                  vos clients.
                </li>
                <li>
                  <span className="checkmark"></span>{' '}
                  <strong>Fabrication sur mesure</strong> : Une expertise unique
                  pour réaliser des corrections hors normes avec une finition
                  impeccable.
                </li>
                <li>
                  <span className="checkmark"></span>{' '}
                  <strong>Large choix de matériaux</strong> : Des options variées
                  pour allier confort, résistance et performance visuelle.
                </li>
                <li>
                  <span className="checkmark"></span>{' '}
                  <strong>Service client dédié</strong> : Un accompagnement
                  personnalisé pour vous offrir les meilleures solutions en
                  fonction de chaque cas particulier.
                </li>
                <li>
                  <span className="checkmark"></span>{' '}
                  <strong>Un très grand choix de verres bifocaux</strong> : Nous
                  offrons une large gamme de verres bifocaux, adaptés à chaque
                  besoin visuel et confort optimal.
                </li>
                <li>
                  <span className="checkmark"></span>{' '}
                  <strong>Sélection diversifiée de designs</strong> : une gamme
                  variée de designs de verres, incluant RD24, RD28, RD38, ST25, ST28,
                  ST35, ST45, Exécutif, Trifocal 7X28, Trifocal 8X35 et Omega RD24.
                </li>
              </ul>
            </section>
          </section>

          <section id="partenaires" className="partners">
            <h2>Fier partenaire des cliniques indépendantes du Canada</h2>
            <div className="partners-grid">
              <div className="partner-card">
                <img src={Iris} alt="IRIS" />
              </div>
              <div className="partner-card">
                <img src={SOI} alt="Groupe SOI" />
              </div>
              <div className="partner-card">
                <img src={OptoReseau} alt="Opto Réseau" />
              </div>
              <div className="partner-card">
                <img src={RegardAction} alt="Regard Action" />
              </div>
            </div>
          </section>
        </main>

        <footer className="footer">
          <Container>
            <Row>
              <Col xs="12" className="text-center">
                <p>OLABPLUS Inc. 2500 CHEMIN DES PRAIRIES, BROSSARD, (QC) J4Y 2X2 CANADA</p>
                <p>T. 450-466-2862 T. 1-800-761-2862 F. 450-656-6829 F. 450-486-3827</p>
                <p>CONTACT@OLABPLUS.CA</p>
                <p>© OLABPLUS 1987 - 2024. Tous droits réservés</p>
              </Col>
            </Row>
          </Container>
        </footer>
      </>
  );
};

export default Home;
