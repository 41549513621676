import React from 'react';
import { Container, Card, CardBody, CardHeader, Table } from 'reactstrap';

const OrderPreviewComponent = ({ rxState, lenState, frameState, instructions, date, client, clients, isSwitchOnPrisme }) => {
    const currentClient = clients.find((c) => c._id === client);

    // Helper function to format values with a '+' sign for positive numbers
    const formatValue = (value, isPositive = false) => {
        if (isPositive && value > 0) {
            return `+${value}`;
        }
        return value;
    };

    return (
        <Container className="summary-container">
            <Card className="invoice-card">
                <CardHeader className="invoice-header text-center">
                    <h4>Résumé de la Commande</h4>
                </CardHeader>
                <CardBody>

                    {/* Informations Générales */}
                    <div className="section">
                        <h5 className="section-title">Informations Générales</h5>
                        <Table borderless className="info-table">
                            <tbody>
                            <tr>
                                <td><strong>Date:</strong></td>
                                <td>{date}</td>
                            </tr>
                            <tr>
                                <td><strong>Nom du client:</strong></td>
                                <td>{currentClient?.name}</td>
                            </tr>
                            <tr>
                                <td><strong>Nom du patient</strong></td>
                                <td>{rxState?.name}</td>
                            </tr>
                            </tbody>
                        </Table>
                    </div>

                    {/* Rx Information */}
                    <div className="section">
                        <h5 className="section-title">Prescription (Rx)</h5>
                        <Table bordered>
                            <thead>
                            <tr>
                                <th></th>
                                <th>OD</th>
                                <th>OS</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr><td>Commande</td><td>{rxState?.OD}</td><td>{rxState?.OS}</td></tr>
                            <tr><td>SPH</td><td>{formatValue(rxState?.SPH?.OD, true)}</td><td>{formatValue(rxState?.SPH?.OS, true)}</td></tr>
                            <tr><td>CYL</td><td>{formatValue(rxState?.CYL?.OD)}</td><td>{formatValue(rxState.CYL.OS)}</td></tr>
                            <tr><td>AXE</td><td>{rxState?.AXE?.OD}</td><td>{rxState?.AXE?.OS}</td></tr>
                            <tr><td>ADD</td><td>{formatValue(rxState?.ADD?.OD, true)}</td><td>{formatValue(rxState?.ADD?.OS, true)}</td></tr>
                            {isSwitchOnPrisme && (
                                <>
                                    <tr><td>Prisme 1</td><td>{formatValue(rxState?.prisme1?.OD)}</td><td>{formatValue(rxState?.prisme1?.OS)}</td></tr>
                                    <tr><td>Direction IO</td><td>{rxState?.IO?.OD}</td><td>{rxState?.IO?.OS}</td></tr>
                                    <tr><td>Prisme 2</td><td>{formatValue(rxState?.prisme2?.OD)}</td><td>{formatValue(rxState?.prisme2?.OS)}</td></tr>
                                    <tr><td>Direction UD</td><td>{rxState?.UD?.OD}</td><td>{rxState?.UD?.OS}</td></tr>
                                </>
                            )}
                            </tbody>
                        </Table>
                    </div>

                    {/* Verres Details */}
                    <div className="section">
                        <h5 className="section-title">Détails des Verres</h5>
                        <Table borderless className="info-table">
                            <tbody>
                            <tr><td><strong>Type:</strong></td><td>{lenState?.type}</td></tr>
                            <tr><td><strong>Design:</strong></td><td>{lenState?.design?.OD}</td></tr>
                            {!lenState?.identical && <tr><td><strong>Design OS:</strong></td><td>{lenState?.design?.OS}</td></tr>}
                            <tr><td><strong>Traitement:</strong></td><td>{lenState?.treatment}</td></tr>
                            <tr><td><strong>Index:</strong></td><td>{lenState?.index}</td></tr>
                            <tr><td><strong>Options Extra:</strong></td><td>{lenState?.options || "N/A"}</td></tr>
                            <tr><td><strong>Épaisseur:</strong></td><td>{lenState?.thickness}</td></tr>
                            <tr><td><strong>Courbure de base:</strong></td><td>{lenState?.curve?.curve}</td></tr>
                            {lenState.curve.curve === 'Forcer' && (
                                <>
                                    <tr><td><strong>Courbure OD:</strong></td><td>{lenState?.curve?.OD}</td></tr>
                                    <tr><td><strong>Courbure OS:</strong></td><td>{lenState?.curve?.OS}</td></tr>
                                </>
                            )}
                            <tr><td><strong>PD Loin OD:</strong></td><td>{lenState?.PDdistant?.OD || "N/A"}</td></tr>
                            <tr><td><strong>PD Loin OS:</strong></td><td>{lenState?.PDdistant?.OS || "N/A"}</td></tr>
                            <tr><td><strong>PD Près OD:</strong></td><td>{lenState?.PDclose?.OD || "N/A"}</td></tr>
                            <tr><td><strong>PD Près OS:</strong></td><td>{lenState?.PDclose?.OS || "N/A"}</td></tr>
                            <tr><td><strong>Hauteur CO OD:</strong></td><td>{lenState?.COheight?.OD || "N/A"}</td></tr>
                            <tr><td><strong>Hauteur CO OS:</strong></td><td>{lenState?.COheight?.OS || "N/A"}</td></tr>
                            </tbody>
                        </Table>
                    </div>

                    {/* Teinte (if enabled) */}
                    {lenState.shade?.exists && (
                        <div className="section">
                            <h5 className="section-title">Teinte</h5>
                            <Table borderless className="info-table">
                                <tbody>
                                <tr><td><strong>Type:</strong></td><td>{lenState?.shade?.type}</td></tr>
                                <tr><td><strong>Couleur:</strong></td><td>{lenState?.shade?.color}</td></tr>
                                <tr><td><strong>Intensité:</strong></td><td>{lenState?.shade?.intensity}%</td></tr>
                                {lenState?.shade?.type === "Teinte dégradée" && (
                                    <tr><td><strong>Intensité Bis:</strong></td><td>{lenState?.shade?.intensitybis}%</td></tr>
                                )}
                                </tbody>
                            </Table>
                        </div>
                    )}

                    {/* Miroirs (if enabled) */}
                    {lenState?.mirrorOptions?.exists && (
                        <div className="section">
                            <h5 className="section-title">Option Miroir</h5>
                            <Table borderless className="info-table">
                                <tbody>
                                <tr><td><strong>Type:</strong></td><td>{lenState?.mirrorOptions?.type}</td></tr>
                                </tbody>
                            </Table>
                        </div>
                    )}

                    {/* Monture Details */}
                    <div className="section">
                        <h5 className="section-title">Détails de la Monture</h5>
                        <Table borderless className="info-table">
                            <tbody>
                            <tr><td><strong>Marque:</strong></td><td>{frameState?.brand}</td></tr>
                            <tr><td><strong>Modèle:</strong></td><td>{frameState?.model}</td></tr>
                            <tr><td><strong>Couleur:</strong></td><td>{frameState?.color}</td></tr>
                            <tr><td><strong>Type:</strong></td><td>{frameState?.type}</td></tr>
                            <tr>
                                <td><strong>Dimensions:</strong></td>
                                <td>A: {frameState?.dimensions?.A}, Pont: {frameState?.dimensions?.bridge}, B: {frameState?.dimensions?.B}, ED: {frameState?.dimensions?.ED}, Côté: {frameState?.dimensions?.temple}</td>
                            </tr>
                            </tbody>
                        </Table>
                    </div>

                    {/* Instructions */}
                    <div className="section">
                        <h5 className="section-title">Instructions</h5>
                        <p>{instructions || "Aucune instruction spécifique"}</p>
                    </div>
                </CardBody>
            </Card>
        </Container>
    );
};

export default OrderPreviewComponent;
