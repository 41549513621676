import './css/Admin.css';
import React, {useEffect, useRef, useState} from 'react';
import {Button, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Table} from 'reactstrap';
import {baseUrl} from '../shared/baseUrl';
import ReactPaginate from 'react-paginate';
import {SiMicrosoftexcel , SiMicrosoftword} from 'react-icons/si';
import {IoAddCircleOutline} from "react-icons/io5";
import {ImBin, ImPencil} from 'react-icons/im';
import axios from 'axios';
import * as XLSX from 'xlsx';
import { Document, Packer, TextRun, Paragraph, AlignmentType, HeadingLevel, PageOrientation } from 'docx';
import { saveAs } from 'file-saver';



function parseDateTime(dateTimeString) {
    const dateTime = new Date(dateTimeString);
    const options = {
        day: 'numeric',
        month: 'long',
        hour: 'numeric',
        minute: 'numeric',
        hour12: false,
    };
    const formattedDate = dateTime.toLocaleString('fr-FR', options);
    return formattedDate.replace('à', '•');
}

const UserComponent = () => {
    const [pageIndex, setPageIndex] = useState(0);
    const [totalPageCount, setTotalPageCount] = useState(0);
    const [users, setUsers] = useState([]);
    const [selectedUsers, setSelectedUsers] = useState(new Set());
    const [isLoading, setIsLoading] = useState(false);
    const [selectAll, setSelectAll] = useState(false);

    // Edit modal state
    const [isEditModalOpen, setIsEditModalOpen] = useState(false);
    const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
    const [selectedUser, setSelectedUser] = useState(null);
    const [updatedUser, setUpdatedUser] = useState({
        name: '', email: '', code: '', accountType: '', address: '', password: '', accountingEmail: '', orderEmail: '', contactPerson: '', clientGroup: '',
        invoice: { address: '', postalCode: '', city: '', province: '', phone: '', phone2: '', fax: '' },
        expedition: { address: '', postalCode: '', city: '', province: '', phone: '', phone2: '', fax: '' },
    });

    const [newUser, setNewUser] = useState({
        name: '', email: '', code: '', accountType: '', address: '', password: '', accountingEmail: '', orderEmail: '', contactPerson: '', clientGroup: '',
        invoice: { address: '', postalCode: '', city: '', province: '', phone: '', phone2: '', fax: '' },
        expedition: { address: '', postalCode: '', city: '', province: '', phone: '', phone2: '', fax: '' },
    });

    const [searchTerm, setSearchTerm] = useState('');

    const handleUseFacturationAddress = (e) => {
        const isChecked = e.target.checked;
        setNewUser(prevState => ({
            ...prevState,
            useInvoiceForExpedition: isChecked,  // Add this flag to track the checkbox state
            expedition: isChecked
                ? {
                    ...prevState.expedition,
                    address: prevState.invoice.address,
                    postalCode: prevState.invoice.postalCode,
                    city: prevState.invoice.city,
                    province: prevState.invoice.province
                }
                : {
                    address: '',
                    postalCode: '',
                    city: '',
                    province: ''
                }
        }));
    };


    const handleEditUseFacturationAddress = (e) => {
        const isChecked = e.target.checked;
        setUpdatedUser(prevState => ({
            ...prevState,
            useInvoiceForExpedition: isChecked,
            expedition: isChecked
                ? {
                    ...prevState.expedition,
                    address: prevState.invoice.address,
                    postalCode: prevState.invoice.postalCode,
                    city: prevState.invoice.city,
                    province: prevState.invoice.province
                }
                : {
                    address: '',
                    postalCode: '',
                    city: '',
                    province: ''
                }
        }));
    };



    const handleCheckboxChange = (userId) => {
        setSelectedUsers(prevSelected => {
            const newSet = new Set(prevSelected);
            if (newSet.has(userId)) {
                newSet.delete(userId);
            } else {
                newSet.add(userId);
            }
            return newSet;
        });
    };

    const handleSelectAll = (e) => {
        setSelectAll(e.target.checked);
        if (e.target.checked) {
            setSelectedUsers(new Set(['ALL']));
        } else {
            setSelectedUsers(new Set());
        }
    };

    const fileInputRef = useRef(null);

    const handleImportClick = () => {
        fileInputRef.current.click();
    };

    const handleFileUpload = (e) => {
        const file = e.target.files[0];
        if (file) {
            importUsersFromExcel(file);
        }
    };

    const importUsersFromExcel = async (file) => {
        try {
            // Read the Excel file
            const data = await file.arrayBuffer();
            const workbook = XLSX.read(data, { type: 'array' });
            const sheetName = workbook.SheetNames[0];
            const worksheet = workbook.Sheets[sheetName];
            const jsonData = XLSX.utils.sheet_to_json(worksheet);

            // Send data to backend
            const bearer = 'Bearer ' + localStorage.getItem('token');
            await axios.post(
                baseUrl + 'user/user/import',
                { users: jsonData },
                {
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: bearer,
                    },
                }
            );

            alert('Users imported successfully');
            fetchUsers(pageIndex);
        } catch (error) {
            console.error('Error importing users:', error);
            alert('Failed to import users');
        }
    };

    const exportSelectedUsersToDocx = async () => {
        const userIds = Array.from(selectedUsers);

        try {
            const bearer = 'Bearer ' + localStorage.getItem('token');
            let response;

            // If "Select All" is checked, fetch all users
            if (userIds.includes('ALL')) {
                response = await axios.post(baseUrl + "user/user/export", {}, {
                    headers: { 'Authorization': bearer }
                });
            } else {
                response = await axios.post(baseUrl + "user/user/export", { userIds }, {
                    headers: { 'Authorization': bearer }
                });
            }

            const usersData = response.data;

            // Create an array to hold the document content
            const docContent = [];

            // Iterate over each user and create a card
            usersData.forEach((user, index) => {
                // Add a heading for each user
                docContent.push(
                    new Paragraph({
                        text: `Compte ${index + 1}`,
                        heading: HeadingLevel.HEADING_2,
                        alignment: AlignmentType.CENTER,
                        spacing: { after: 300 },
                    })
                );

                // Create an array of field names and values
                const fields = [
                    { name: 'ID', value: user._id },
                    { name: 'Nom de client', value: user.name || '' },
                    { name: 'Courriel', value: user.email || '' },
                    { name: 'Numéro de compte', value: user.code || '' },
                    { name: 'Type de compte', value: user.accountType || '' },
                    { name: 'Numéro et nom de rue', value: user.address || '' },
                    { name: 'Courriel de comptabilité', value: user.accountingEmail || '' },
                    { name: 'Courriel de commande', value: user.orderEmail || '' },
                    { name: 'Personne à contacter', value: user.contactPerson || '' },
                    { name: 'Groupe de clients', value: user.clientGroup || '' },
                    { name: 'Facturation Numéro et nom de rue', value: user.invoice?.address || '' },
                    { name: 'Facturation Code postal', value: user.invoice?.postalCode || '' },
                    { name: 'Facturation Ville', value: user.invoice?.city || '' },
                    { name: 'Facturation Province', value: user.invoice?.province || '' },
                    { name: 'Facturation Téléphone', value: user.invoice?.phone || '' },
                    { name: 'Facturation Téléphone 2', value: user.invoice?.phone2 || '' },
                    { name: 'Facturation Télécopieur', value: user.invoice?.fax || '' },
                    { name: 'Expédition Numéro et nom de rue', value: user.expedition?.address || '' },
                    { name: 'Expédition Code postal', value: user.expedition?.postalCode || '' },
                    { name: 'Expédition Ville', value: user.expedition?.city || '' },
                    { name: 'Expédition Province', value: user.expedition?.province || '' },
                    { name: 'Expédition Téléphone', value: user.expedition?.phone || '' },
                    { name: 'Expédition Téléphone 2', value: user.expedition?.phone2 || '' },
                    { name: 'Expédition Télécopieur', value: user.expedition?.fax || '' },
                    { name: 'Created', value: parseDateTime(user.created) },
                ];

                // Add each field as a paragraph
                fields.forEach(field => {
                    docContent.push(
                        new Paragraph({
                            children: [
                                new TextRun({
                                    text: `${field.name}: `,
                                    bold: true,
                                }),
                                new TextRun({
                                    text: field.value,
                                }),
                            ],
                            spacing: { after: 200 },
                        })
                    );
                });

                // Add a horizontal line between users, except after the last user
                if (index < usersData.length - 1) {
                    docContent.push(
                        new Paragraph({
                            children: [
                                new TextRun({
                                    text: '',
                                }),
                            ],
                            border: {
                                bottom: {
                                    color: "auto",
                                    space: 1,
                                    value: "single",
                                    size: 6,
                                },
                            },
                            spacing: { after: 400 },
                        })
                    );
                }
            });

            // Create the document with landscape orientation
            const doc = new Document({
                sections: [{
                    properties: {
                        page: {
                            size: {
                                orientation: PageOrientation.LANDSCAPE,
                            },
                        },
                    },
                    children: docContent,
                }],
            });

            // Generate the document and prompt download
            const blob = await Packer.toBlob(doc);
            saveAs(blob, 'SelectedUsers.docx');

        } catch (error) {
            console.error('Error exporting users to DOCX:', error);
        }
    };



    const exportSelectedUsers = async () => {
        const userIds = Array.from(selectedUsers);

        try {
            const bearer = 'Bearer ' + localStorage.getItem('token');
            let response;

            // If "Select All" is checked, fetch all users
            if (userIds.includes('ALL')) {
                response = await axios.post(baseUrl + "user/user/export", {}, {
                    headers: { 'Authorization': bearer }
                });
            } else {
                response = await axios.post(baseUrl + "user/user/export", { userIds }, {
                    headers: { 'Authorization': bearer }
                });
            }

            const selectedData = response.data.map(user => ({
                ID: user._id,
                'Nom de client': user.name,
                'Courriel': user.email,
                'Numéro de compte': user.code,
                'Type de compte': user.accountType,
                'Numéro et nom de rue': user.address,
                'Courriel de comptabilité': user.accountingEmail,
                'Courriel de commande': user.orderEmail,
                'Personne à contacter': user.contactPerson,
                'Groupe de clients': user.clientGroup,
                'Facturation Numéro et nom de rue': user.invoice?.address,
                'Facturation Code postal': user.invoice?.postalCode,
                'Facturation Ville': user.invoice?.city,
                'Facturation Province': user.invoice?.province,
                'Facturation Téléphone': user.invoice?.phone,
                'Facturation Téléphone 2': user.invoice?.phone2,
                'Facturation Télécopieur': user.invoice?.fax,
                'Expédition Numéro et nom de rue': user.expedition?.address,
                'Expédition Code postal': user.expedition?.postalCode,
                'Expédition Ville': user.expedition?.city,
                'Expédition Province': user.expedition?.province,
                'Expédition Téléphone': user.expedition?.phone,
                'Expédition Téléphone 2': user.expedition?.phone2,
                'Expédition Télécopieur': user.expedition?.fax,
                Created: parseDateTime(user.created),
            }));

            const worksheet = XLSX.utils.json_to_sheet(selectedData);

            // Calculate and set column widths based on the maximum length of each column
            worksheet['!cols'] = Object.keys(selectedData[0]).map(key => ({
                wch: Math.max(
                    key.length, // Header length
                    ...selectedData.map(row => (row[key] ? row[key].toString().length : 0)) // Data lengths
                )
            }));


            const workbook = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(workbook, worksheet, 'Users');
            XLSX.writeFile(workbook, 'SelectedUsers.xlsx');
        } catch (error) {
            console.error('Error exporting users:', error);
        }
    };


    const fetchUsers = async (page = 0, keyword = '') => {
        try {
            setIsLoading(true);
            const bearer = 'Bearer ' + localStorage.getItem('token');
            const response = await axios.get(baseUrl + 'user/users', {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: bearer,
                },
                params: {
                    search: keyword,
                    pageIndex: page + 1,
                    limit: 10,
                },
            });

            const data = response.data.docs;
            setUsers(data);
            setTotalPageCount(response.data.totalPages);
        } catch (error) {
            console.error('Error fetching users:', error);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        fetchUsers(pageIndex, searchTerm);
    }, [pageIndex, searchTerm]);

    const deleteUser = async (userId) => {
        try {
            const bearer = 'Bearer ' + localStorage.getItem('token');
            await axios.delete(baseUrl + "user/user", {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': bearer
                },
                params: { _id: userId }
            });
            alert('User deleted successfully');
            fetchUsers(pageIndex);
        } catch (error) {
            console.error("Error deleting user:", error);
        }
    };

    const handlePageClick = (pagingInfo) => {
        const newPageIndex = pagingInfo.selected;
        setPageIndex(newPageIndex);
        fetchUsers(newPageIndex);
    };

    const toggleEditModal = (user = null) => {
        setIsEditModalOpen(!isEditModalOpen);
        if (user) {
            setSelectedUser(user);
            setUpdatedUser({
                name: user.name || '',
                email: user.email || '',
                code: user.code || '',
                accountType: user.accountType || '',
                address: user.address || '',
                accountingEmail: user.accountingEmail || '',
                orderEmail: user.orderEmail || '',
                contactPerson: user.contactPerson || '',
                clientGroup: user.clientGroup || '',
                invoice: {
                    address: user.invoice?.address || '',
                    postalCode: user.invoice?.postalCode || '',
                    city: user.invoice?.city || '',
                    province: user.invoice?.province || '',
                    phone: user.invoice?.phone || '',
                    phone2: user.invoice?.phone2 || '',
                    fax: user.invoice?.fax || '',
                },
                expedition: {
                    address: user.expedition?.address || '',
                    postalCode: user.expedition?.postalCode || '',
                    city: user.expedition?.city || '',
                    province: user.expedition?.province || '',
                    phone: user.expedition?.phone || '',
                    phone2: user.expedition?.phone2 || '',
                    fax: user.expedition?.fax || '',
                },
            });
        }
    };

    const handleInputChange = (e, setFunction, section = null) => {
        const { name, value } = e.target;

        setFunction(prevState => {
            let updatedValue = value;

            // Auto-format Canadian postal code
            if (name === 'postalCode') {
                updatedValue = value.toUpperCase().replace(/[^A-Za-z0-9]/g, ''); // Remove non-alphanumeric characters
                if (updatedValue.length > 3) {
                    updatedValue = `${updatedValue.slice(0, 3)} ${updatedValue.slice(3, 6)}`.trim();
                }
            }

            // Auto-format phone numbers as XXX-XXX-XXXX
            if (name === 'phone' || name === 'phone2') {
                updatedValue = value.replace(/\D/g, ''); // Remove non-numeric characters
                if (updatedValue.length > 3 && updatedValue.length <= 6) {
                    updatedValue = `${updatedValue.slice(0, 3)}-${updatedValue.slice(3)}`;
                } else if (updatedValue.length > 6) {
                    updatedValue = `${updatedValue.slice(0, 3)}-${updatedValue.slice(3, 6)}-${updatedValue.slice(6, 10)}`;
                }
            }

            const updatedSection = section ? { ...prevState[section], [name]: updatedValue } : { [name]: updatedValue };
            const updatedState = section ? { ...prevState, [section]: updatedSection } : { ...prevState, ...updatedSection };

            // Only copy address-related fields from `invoice` to `expedition`
            const addressFields = ['address', 'postalCode', 'city', 'province'];
            if (section === 'invoice' && prevState.useInvoiceForExpedition && addressFields.includes(name)) {
                updatedState.expedition = {
                    ...prevState.expedition,
                    [name]: updatedValue
                };
            }

            return updatedState;
        });
    };



    const updateUser = async (e) => {
        e.preventDefault();
        try {
            const bearer = 'Bearer ' + localStorage.getItem('token');
            await axios.put(baseUrl + "user/user", {
                _id: selectedUser._id,
                ...updatedUser
            }, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': bearer
                }
            });
            alert('User updated successfully');
            toggleEditModal();
            fetchUsers(pageIndex);
        } catch (error) {
            console.error("Error updating user:", error);
        }
    };

    const createUser = async (e) => {
        e.preventDefault();
        try {
            const bearer = 'Bearer ' + localStorage.getItem('token');
            await axios.post(baseUrl + "user/user", newUser, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': bearer
                }
            });
            alert('User created successfully');
            toggleCreateModal();
            fetchUsers(pageIndex);
        } catch (error) {
            console.error("Error creating user:", error);
        }
    };

    const toggleCreateModal = () => {
        setIsCreateModalOpen(!isCreateModalOpen);
        setNewUser({ name: '', email: '', accountType: '', code: '' , address: '', password: ''
        , accountingEmail: '', orderEmail: '', contactPerson: '', clientGroup: ''
            , invoice: { address: '', postalCode: '', city: '', province: '', phone: '', phone2: '', fax: '' }
        , expedition: { address: '', postalCode: '', city: '', province: '', phone: '', phone2: '', fax: '' }, });
    };

    useEffect(() => {
        fetchUsers(pageIndex);
    }, []);

    return (
        <div className='adminContainer'>
            <div style={{display: 'flex' , justifyContent: 'space-between' , width: '1180px'}}>
                <div className="search-container" style={{display:'flex' , flexDirection: 'column', justifyContent:'flex-end'}}>
                    <Input
                        type="text"
                        placeholder="Rechercher"
                        value={searchTerm}
                        onChange={(e) => {
                            setSearchTerm(e.target.value);
                            setPageIndex(0); // Reset to first page when search term changes
                        }}
                    />
                </div>

                <div className={'buttons-container'}>
                    <Button style={{background: '#e29a10', borderColor: "#e29a10"}} onClick={handleImportClick}>
                        Importer utilisateurs
                    </Button>

                    <input
                        type="file"
                        accept=".xlsx, .xls"
                        ref={fileInputRef}
                        style={{display: 'none'}}
                        onChange={handleFileUpload}
                    />

                    <Button color="success" onClick={toggleCreateModal}> Créer Compte <IoAddCircleOutline
                        style={{fontSize: '25px'}}/></Button>
                    <Button style={{background: '#e29a10', borderColor: "#e29a10"}} onClick={exportSelectedUsers}
                            disabled={selectedUsers.length === 0}>
                        Export compte(s) selectionnée(s) <SiMicrosoftexcel style={{fontSize: '25px'}}/>
                    </Button>
                    <Button style={{background: '#e29a10', borderColor: "#e29a10"}} onClick={exportSelectedUsersToDocx}
                            disabled={selectedUsers.length === 0}>
                        Export Docx <SiMicrosoftword style={{fontSize: '25px'}}/>
                    </Button>
                </div>
            </div>
            <div className='adminHeader'>Users List</div>
            <div className='orderListContainer-admin-history'>
                <div className='orders-pagination'>
                    <ReactPaginate
                        className="react-paginate"
                        breakLabel="..."
                        nextLabel="next >"
                        onPageChange={handlePageClick}
                        pageRangeDisplayed={5}
                        pageCount={totalPageCount}
                        forcePage={pageIndex}
                        previousLabel="< previous"
                        renderOnZeroPageCount={null}
                    />
                </div>
                <div className='orderList-admin orderList-admin-history'>
                    <Table striped responsive>
                        <thead>
                        <tr>
                            <th>ID</th>
                            <th>Type de compte</th>
                            <th>Address</th>
                            <th>Name</th>
                            <th>Code</th>
                            <th>Email</th>
                            <th>Created</th>
                            <th>Actions</th>
                            <th>
                            <Input type="checkbox" onChange={handleSelectAll} checked={selectAll}/>
                            </th>
                        </tr>
                        </thead>
                        <tbody>
                        {isLoading ? (
                            <tr>
                                <td colSpan="8" className="text-center">Loading...</td>
                            </tr>
                        ) : users.length > 0 ? (
                            users.map((ele) => (
                                <tr key={ele._id}>
                                    <td>{ele._id}</td>
                                    <td>{ele.accountType}</td>
                                    <td>{ele.address}</td>
                                    <td>{ele.name}</td>
                                    <td>{ele.code}</td>
                                    <td>{ele.email}</td>
                                    <td>{parseDateTime(ele.created)}</td>
                                    <td>
                                        <Button
                                            onClick={() => toggleEditModal(ele)}
                                            style={{backgroundColor: 'transparent', border: 'none'}}
                                        >
                                            <ImPencil size='20' color='#694708'/>
                                        </Button>
                                        <Button
                                            onClick={() => {
                                                if (window.confirm('Are you sure you wish to delete this item?')) {
                                                    deleteUser(ele._id);
                                                }
                                            }}
                                            style={{backgroundColor: 'transparent', border: 'none'}}
                                        >
                                            <ImBin size='20' color='#694708'/>
                                        </Button>
                                    </td>
                                    <td>
                                        <td>
                                            <Input
                                                type="checkbox"
                                                checked={selectAll || selectedUsers.has(ele._id)}
                                                onChange={() => handleCheckboxChange(ele._id)}
                                                disabled={selectAll}
                                            />
                                        </td>
                                    </td>
                                </tr>
                            ))
                        ) : (
                            <tr>
                                <td colSpan="8" className="text-center">No users found.</td>
                            </tr>
                        )}
                        </tbody>
                    </Table>
                </div>
            </div>

            {/* Edit Modal */}
            <Modal isOpen={isEditModalOpen} toggle={toggleEditModal} size={'xl'} fullscreen={'xl'}>
                <ModalHeader toggle={toggleEditModal}>Edit User</ModalHeader>
                <ModalBody>
                    <form onSubmit={updateUser}>
                        <div className="row">
                            <div className={'col-md-4'}>
                                <h5>Général</h5>
                                <FormGroup>
                                    <Label>Numéro de compte</Label>
                                    <Input type="text" name="code" placeholder={'Entrez le numéro de compte'}
                                           value={updatedUser.code}
                                           onChange={e => handleInputChange(e, setUpdatedUser)}/>
                                </FormGroup>
                                <FormGroup>
                                    <Label>Nom de client</Label>
                                    <Input required={true} name="name" value={updatedUser.name}
                                           placeholder={'Entrez le nom de client'}
                                           onChange={e => handleInputChange(e, setUpdatedUser)}/>
                                </FormGroup>
                                <FormGroup>
                                    <Label>Courriel</Label>
                                    <Input required={true} type={'email'} name="email" value={updatedUser.email}
                                           placeholder="Entrez le courriel"
                                           onChange={e => handleInputChange(e, setUpdatedUser)}/>
                                </FormGroup>
                                <FormGroup>
                                    <Label>Type de compte</Label>
                                    <Input type={'select'} value={updatedUser.accountType} name="accountType"
                                           value={updatedUser.accountType}
                                           onChange={e => handleInputChange(e, setUpdatedUser)}>
                                        <option value="">Type de compte</option>
                                        <option value="user">User</option>
                                        <option value="admin">Admin</option>
                                    </Input>
                                    {/*<Input name="accountType" value={updatedUser.accountType}*/}
                                    {/*       onChange={e => handleInputChange(e, setUpdatedUser)}/>*/}
                                </FormGroup>
                                <FormGroup>
                                    <Label>Mot de passe</Label>
                                    <Input autoComplete={'new-password'} type="password" name="password"
                                           value={updatedUser.password} placeholder="Entrez un mot de passe"
                                           onChange={e => handleInputChange(e, setUpdatedUser)}/>
                                </FormGroup>
                                <FormGroup>
                                    <Label>Numéro et nom de rue</Label>
                                    <Input name="address" value={updatedUser.address} placeholder="Entrez l'adresse"
                                           onChange={e => handleInputChange(e, setUpdatedUser)}/>
                                </FormGroup>
                                <FormGroup>
                                    <Label>Courriel de comptabilité</Label>
                                    <Input type="email" name="accountingEmail"
                                           value={updatedUser.accountingEmail}
                                           placeholder="Entrez le courriel de comptabilité"
                                           onChange={e => handleInputChange(e, setUpdatedUser)}/>
                                </FormGroup>
                                <FormGroup>
                                    <Label>Courriel de commande</Label>
                                    <Input type="email" name="orderEmail"
                                           value={updatedUser.orderEmail} placeholder="Entrez le courriel de commande"
                                           onChange={e => handleInputChange(e, setUpdatedUser)}/>
                                </FormGroup>
                                <FormGroup>
                                    <Label>Personne à contacter</Label>
                                    <Input type="text" name="contactPerson"
                                           value={updatedUser.contactPerson}
                                           placeholder="Entrez le nom de la personne à contacter"
                                           onChange={e => handleInputChange(e, setUpdatedUser)}/>
                                </FormGroup>
                                <FormGroup>
                                    <Label>Groupe de clients</Label>
                                    <Input type="text" name="clientGroup"
                                           value={updatedUser.clientGroup} placeholder={'Entrez le groupe de clients'}
                                           onChange={e => handleInputChange(e, setUpdatedUser)}/>
                                </FormGroup>
                            </div>

                            <div className={'col-md-4'}>
                                <h5>Facturation</h5>
                                <FormGroup>
                                    <Label>Numéro et nom de rue</Label>
                                    <Input name="address" value={updatedUser.invoice.address}
                                           placeholder="Entrez l'adresse"
                                           onChange={e => handleInputChange(e, setUpdatedUser, 'invoice')}/>
                                </FormGroup>
                                <FormGroup>
                                    <Label>Code postal</Label>
                                    <Input name="postalCode" value={updatedUser.invoice.postalCode}
                                           placeholder="Entrez le code postal"
                                           onChange={e => handleInputChange(e, setUpdatedUser, 'invoice')}/>
                                </FormGroup>
                                <FormGroup>
                                    <Label>Ville</Label>
                                    <Input name="city" value={updatedUser.invoice.city} placeholder={'Entrez la ville'}
                                           onChange={e => handleInputChange(e, setUpdatedUser, 'invoice')}/>
                                </FormGroup>
                                <FormGroup>
                                    <Label>Province</Label>
                                    <Input type={'select'} name="province" value={updatedUser.invoice.province}
                                             onChange={e => handleInputChange(e, setUpdatedUser, 'invoice')}>
                                        <option value="">Entrez la province</option>
                                        <option value="AB">AB</option>
                                        <option value="BC">BC</option>
                                        <option value="MB">MB</option>
                                        <option value="NB">NB</option>
                                        <option value="NL">NL</option>
                                        <option value="NS">NS</option>
                                        <option value="NT">NT</option>
                                        <option value="NU">NU</option>
                                        <option value="ON">ON</option>
                                        <option value="PE">PE</option>
                                        <option value="QC">QC</option>
                                        <option value="SK">SK</option>
                                        <option value="YT">YT</option>
                                    </Input>
                                    {/*<Input name="province" value={updatedUser.invoice.province}*/}
                                    {/*       placeholder="Entrez la province"*/}
                                    {/*       onChange={e => handleInputChange(e, setUpdatedUser, 'invoice')}/>*/}
                                </FormGroup>
                                <FormGroup>
                                    <Label>Téléphone</Label>
                                    <Input name="phone" value={updatedUser.invoice.phone}
                                           placeholder={'Entrez le téléphone'}
                                           onChange={e => handleInputChange(e, setUpdatedUser, 'invoice')}/>
                                </FormGroup>
                                <FormGroup>
                                    <Label>Téléphone 2</Label>
                                    <Input name="phone2" value={updatedUser.invoice.phone2}
                                           placeholder={'Entrez le téléphone 2'}
                                           onChange={e => handleInputChange(e, setUpdatedUser, 'invoice')}/>
                                </FormGroup>
                                <FormGroup>
                                    <Label>Télécopieur</Label>
                                    <Input name="fax" value={updatedUser.invoice.fax}
                                           placeholder={'Entrez le télécopieur'}
                                           onChange={e => handleInputChange(e, setUpdatedUser, 'invoice')}/>
                                </FormGroup>

                            </div>
                            <div className={'col-md-4'}>
                                <h5>Expédition</h5>
                                <FormGroup check>
                                    <Label check>
                                        <Input type="checkbox" onChange={handleEditUseFacturationAddress} />
                                        Utiliser l'adresse de Facturation
                                    </Label>
                                </FormGroup>
                                <FormGroup>
                                    <Label>Numéro et nom de rue</Label>
                                    <Input name="address" value={updatedUser.expedition.address}
                                           placeholder="Entrez l'adresse"
                                           onChange={e => handleInputChange(e, setUpdatedUser, 'expedition')}/>
                                </FormGroup>
                                <FormGroup>
                                    <Label>Code postal</Label>
                                    <Input name="postalCode" value={updatedUser.expedition.postalCode}
                                           placeholder="Entrez le code postal"
                                           onChange={e => handleInputChange(e, setUpdatedUser, 'expedition')}/>
                                </FormGroup>
                                <FormGroup>
                                    <Label>Ville</Label>
                                    <Input name="city" value={updatedUser.expedition.city} placeholder="Entrez la ville"
                                           onChange={e => handleInputChange(e, setUpdatedUser, 'expedition')}/>
                                </FormGroup>
                                <FormGroup>
                                    <Label>Province</Label>
                                    <Input name="provice" type={'select'}
                                             value={updatedUser.expedition.province}
                                                onChange={e => handleInputChange(e, setUpdatedUser, 'expedition')}>
                                        <option value="">Entrez la province</option>
                                        <option value="AB">AB</option>
                                        <option value="BC">BC</option>
                                        <option value="MB">MB</option>
                                        <option value="NB">NB</option>
                                        <option value="NL">NL</option>
                                        <option value="NS">NS</option>
                                        <option value="NT">NT</option>
                                        <option value="NU">NU</option>
                                        <option value="ON">ON</option>
                                        <option value="PE">PE</option>
                                        <option value="QC">QC</option>
                                        <option value="SK">SK</option>
                                        <option value="YT">YT</option>
                                    </Input>
                                    {/*<Input name="province" value={updatedUser.expedition.province}*/}
                                    {/*       placeholder={'Entrez la province'}*/}
                                    {/*       onChange={e => handleInputChange(e, setUpdatedUser, 'expedition')}/>*/}
                                </FormGroup>
                                <FormGroup>
                                    <Label>Téléphone</Label>
                                    <Input name="phone" value={updatedUser.expedition.phone}
                                           placeholder={'Entrez le téléphone'}
                                           onChange={e => handleInputChange(e, setUpdatedUser, 'expedition')}/>
                                </FormGroup>
                                <FormGroup>
                                    <Label>Téléphone 2</Label>
                                    <Input name="phone2" value={updatedUser.expedition.phone2}
                                           placeholder={'Entrez le téléphone 2'}
                                           onChange={e => handleInputChange(e, setUpdatedUser, 'expedition')}/>
                                </FormGroup>
                                <FormGroup>
                                    <Label>Télécopieur</Label>
                                    <Input name="fax" value={updatedUser.expedition.fax}
                                           placeholder={'Entrez le télécopieur'}
                                           onChange={e => handleInputChange(e, setUpdatedUser, 'expedition')}/>
                                </FormGroup>
                            </div>
                        </div>

                        <ModalFooter>
                            <Button color="primary" type={'submit'}>Sauvegarder</Button>
                        </ModalFooter>
                    </form>
                </ModalBody>
            </Modal>

            {/* Create User Modal */}
            <Modal isOpen={isCreateModalOpen} toggle={toggleCreateModal} size={'xl'} fullscreen={'xl'}>
                <ModalHeader>Créer Compte</ModalHeader>
                <ModalBody>
                    <form onSubmit={createUser}>
                        <div className="row">
                            <div className={'col-md-4'}>
                                <h5>Général</h5>
                                <FormGroup>
                                    <Label>Numéro de compte</Label>
                                    <Input required={true} name="code" value={newUser.code}
                                           placeholder={'Entrez le numéro de compte'}
                                           onChange={e => handleInputChange(e, setNewUser)}/>
                                </FormGroup>
                                <FormGroup>
                                    <Label>Nom de client</Label>
                                    <Input required={true} name="name" value={newUser.name}
                                           placeholder={'Entrez le nom de client'}
                                           onChange={e => handleInputChange(e, setNewUser)}/>
                                </FormGroup>
                                <FormGroup>
                                    <Label>Courriel</Label>
                                    <Input type={'email'} required={true} name="email" value={newUser.email}
                                           placeholder={'Entrez le courriel'}
                                           onChange={e => handleInputChange(e, setNewUser)}/>
                                </FormGroup>
                                <FormGroup>
                                    <Label>Type de compte</Label>
                                    <Input type="select" value={newUser.accountType} name="accountType"
                                           onChange={e => handleInputChange(e, setNewUser)}>
                                        <option value="">Type de compte</option>
                                        <option value="user">User</option>
                                        <option value="admin">Admin</option>
                                    </Input>
                                    {/*<Input name="accountType" value={newUser.accountType}*/}
                                    {/*       onChange={e => handleInputChange(e, setNewUser)}/>*/}
                                </FormGroup>
                                <FormGroup>
                                    <Label>Mot de passe</Label>
                                    <Input required={true} autoComplete={'new-password'} type="password" name="password"
                                           value={newUser.password} placeholder={'Entrez un mot de passe'}
                                           onChange={e => handleInputChange(e, setNewUser)}/>
                                </FormGroup>
                                <FormGroup>
                                    <Label>Numéro et nom de rue</Label>
                                    <Input name="address" value={newUser.address} placeholder={'Entrez l\'adresse'}
                                           onChange={e => handleInputChange(e, setNewUser)}/>
                                </FormGroup>
                                <FormGroup>
                                    <Label>Courriel de comptabilité</Label>
                                    <Input type="email" name="accountingEmail"
                                           value={newUser.accountingEmail}
                                           placeholder={'Entrez le courriel de comptabilité'}
                                           onChange={e => handleInputChange(e, setNewUser)}/>
                                </FormGroup>
                                <FormGroup>
                                    <Label>Courriel de commande</Label>
                                    <Input type="email" name="orderEmail"
                                           value={newUser.orderEmail} placeholder={'Entrez le courriel de commande'}
                                           onChange={e => handleInputChange(e, setNewUser)}/>
                                </FormGroup>
                                <FormGroup>
                                    <Label>Personne à contacter</Label>
                                    <Input type="text" name="contactPerson"
                                           value={newUser.contactPerson}
                                           placeholder={'Entrez le nom de la personne à contacter'}
                                           onChange={e => handleInputChange(e, setNewUser)}/>
                                </FormGroup>
                                <FormGroup>
                                    <Label>Groupe de clients</Label>
                                    <Input type="text" name="clientGroup"
                                           value={newUser.clientGroup} placeholder={'Entrez le groupe de clients'}
                                           onChange={e => handleInputChange(e, setNewUser)}/>
                                </FormGroup>
                            </div>

                            <div className={'col-md-4'}>
                                <h5>Facturation</h5>
                                <FormGroup>
                                    <Label>Numéro et nom de rue</Label>
                                    <Input name="address" value={newUser.invoice.address} placeholder="Entrez l'adresse"
                                           onChange={e => handleInputChange(e, setNewUser, 'invoice')}/>
                                </FormGroup>
                                <FormGroup>
                                    <Label>Code postal</Label>
                                    <Input name="postalCode" value={newUser.invoice.postalCode}
                                           placeholder={'Entrez le code postal'}
                                           onChange={e => handleInputChange(e, setNewUser, 'invoice')}/>
                                </FormGroup>
                                <FormGroup>
                                    <Label>Ville</Label>
                                    <Input name="city" value={newUser.invoice.city} placeholder={'Entrez la ville'}
                                           onChange={e => handleInputChange(e, setNewUser, 'invoice')}/>
                                </FormGroup>
                                <FormGroup>
                                    <Label>Province</Label>
                                    <Input name="province" value={newUser.invoice.province}
                                           type="select"
                                             onChange={e => handleInputChange(e, setNewUser, 'invoice')}>
                                        <option value="">Entrez la province</option>
                                        <option value="AB">AB</option>
                                        <option value="BC">BC</option>
                                        <option value="MB">MB</option>
                                        <option value="NB">NB</option>
                                        <option value="NL">NL</option>
                                        <option value="NS">NS</option>
                                        <option value="NT">NT</option>
                                        <option value="NU">NU</option>
                                        <option value="ON">ON</option>
                                        <option value="PE">PE</option>
                                        <option value="QC">QC</option>
                                        <option value="SK">SK</option>
                                        <option value="YT">YT</option>
                                    </Input>
                                    {/*<Input name="province" value={newUser.invoice.province}*/}
                                    {/*       placeholder={'Entrez la province'}*/}
                                    {/*       onChange={e => handleInputChange(e, setNewUser, 'invoice')}/>*/}
                                </FormGroup>
                                <FormGroup>
                                    <Label>Téléphone</Label>
                                    <Input name="phone" value={newUser.invoice.phone}
                                           placeholder={'Entrez le téléphone'}
                                           onChange={e => handleInputChange(e, setNewUser, 'invoice')}/>
                                </FormGroup>
                                <FormGroup>
                                    <Label>Téléphone 2</Label>
                                    <Input name="phone2" value={newUser.invoice.phone2}
                                           placeholder={'Entrez le téléphone 2'}
                                           onChange={e => handleInputChange(e, setNewUser, 'invoice')}/>
                                </FormGroup>
                                <FormGroup>
                                    <Label>Télécopieur</Label>
                                    <Input name="fax" value={newUser.invoice.fax} placeholder={'Entrez le télécopieur'}
                                           onChange={e => handleInputChange(e, setNewUser, 'invoice')}/>
                                </FormGroup>
                            </div>

                            <div className={'col-md-4'}>
                                <h5>Expédition</h5>
                                <FormGroup check>
                                    <Label check>
                                        <Input type="checkbox" onChange={handleUseFacturationAddress} />
                                        Utiliser l'adresse de Facturation
                                    </Label>
                                </FormGroup>
                                <FormGroup>
                                    <Label>Numéro et nom de rue</Label>
                                    <Input name="address" value={newUser.expedition.address}
                                           placeholder={'Entrez l\'adresse'}
                                           onChange={e => handleInputChange(e, setNewUser, 'expedition')}/>
                                </FormGroup>
                                <FormGroup>
                                    <Label>Code postal</Label>
                                    <Input name="postalCode" value={newUser.expedition.postalCode}
                                           placeholder={'Entrez le code postal'}
                                           onChange={e => handleInputChange(e, setNewUser, 'expedition')}/>
                                </FormGroup>
                                <FormGroup>
                                    <Label>Ville</Label>
                                    <Input name="city" value={newUser.expedition.city} placeholder={'Entrez la ville'}
                                           onChange={e => handleInputChange(e, setNewUser, 'expedition')}/>
                                </FormGroup>
                                <FormGroup>
                                    <Label>Province</Label>
                                    <Input type="select" name="province" value={newUser.expedition.province}
                                             onChange={e => handleInputChange(e, setNewUser, 'expedition')}>
                                        <option value="">Entrez la province</option>
                                        <option value="AB">AB</option>
                                        <option value="BC">BC</option>
                                        <option value="MB">MB</option>
                                        <option value="NB">NB</option>
                                        <option value="NL">NL</option>
                                        <option value="NS">NS</option>
                                        <option value="NT">NT</option>
                                        <option value="NU">NU</option>
                                        <option value="ON">ON</option>
                                        <option value="PE">PE</option>
                                        <option value="QC">QC</option>
                                        <option value="SK">SK</option>
                                        <option value="YT">YT</option>
                                    </Input>
                                    {/*<Input name="province" value={newUser.expedition.province}*/}
                                    {/*       placeholder={'Entrez la province'}*/}
                                    {/*       onChange={e => handleInputChange(e, setNewUser, 'expedition')}/>*/}
                                </FormGroup>
                                <FormGroup>
                                    <Label>Téléphone</Label>
                                    <Input name="phone" value={newUser.expedition.phone}
                                           placeholder={'Entrez le téléphone'}
                                           onChange={e => handleInputChange(e, setNewUser, 'expedition')}/>
                                </FormGroup>
                                <FormGroup>
                                    <Label>Téléphone 2</Label>
                                    <Input name="phone2" value={newUser.expedition.phone2}
                                           placeholder={'Entrez le téléphone 2'}
                                           onChange={e => handleInputChange(e, setNewUser, 'expedition')}/>
                                </FormGroup>
                                <FormGroup>
                                    <Label>Télécopieur</Label>
                                    <Input name="fax" value={newUser.expedition.fax}
                                           placeholder={'Entrez le télécopieur'}
                                           onChange={e => handleInputChange(e, setNewUser, 'expedition')}/>
                                </FormGroup>
                            </div>
                        </div>
                        <ModalFooter><Button type={'submit'}>Ajouter</Button></ModalFooter>
                    </form>
                </ModalBody>
            </Modal>
        </div>
    );
};

export default UserComponent;
