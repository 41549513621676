import React, {useEffect, useState} from "react";
import { Nav, NavItem, NavLink, TabPane, TabContent, Button, Modal, ModalBody, Label, Input, Form, FormGroup } from 'reactstrap';

import './css/ModalAuth.css';

function ModalAuth({ setMessage ,  message = '', handleAuth, handleLogout, isModalOpenAuth, setisModalOpenAuth, auth }) {
  const [activeTab, setActiveTab] = useState('1');
  const toggleTab = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  useEffect(() => {
    if (isModalOpenAuth === false) {
      setMessage(''); // Clear error message when modal is closed
    }
  }, [isModalOpenAuth]);

  return (
      <Modal
          isOpen={isModalOpenAuth}
          toggle={() => setisModalOpenAuth(!isModalOpenAuth)}
          size={activeTab === '2' ? 'xl' : 'md'} // Make modal larger for "Create" tab
      >
        <ModalBody className="ModalAuthBody">
          <Nav tabs className="NavTabs">
            <NavItem>
              <NavLink
                  onClick={() => toggleTab('1')}
                  style={{ backgroundColor: activeTab === '1' ? '#F7C873' : 'transparent' }}
              >
                Connexion
              </NavLink>
            </NavItem>
            {auth?.user?.accountType === 'admin' && (
                <NavItem>
                  <NavLink
                      onClick={() => toggleTab('2')}
                      style={{ backgroundColor: activeTab === '2' ? '#F7C873' : 'transparent' }}
                  >
                    Créer Compte
                  </NavLink>
                </NavItem>
            )}
          </Nav>

          <TabContent activeTab={activeTab} className="tab-content">
            <TabPane tabId="1">
              {activeTab === '1' && (
                  // Display red label if login failed
                  <Form onSubmit={(e) => handleAuth(e, false)}>
                    {message && <div className="error-message">{message}</div>}
                    <FormGroup className="form-group">
                      <Label for="code" className="form-label">Compte</Label>
                      <Input
                          type="text"
                          name="code"
                          id="code"
                          placeholder="Entrez votre numéro de compte"
                          className="form-input"
                      />
                    </FormGroup>
                    <FormGroup className="form-group">
                      <Label for="password" className="form-label">Password</Label>
                      <Input
                          type="password"
                          name="password"
                          id="password"
                          placeholder="Entrez votre password"
                          className="form-input"
                      />
                    </FormGroup>
                    <div className="d-flex justify-content-between">
                      <Button type="submit" className="form-button">Connexion</Button>
                      <Button className="form-button" onClick={handleLogout}>Déconnexion</Button>
                    </div>
                  </Form>
              )}
            </TabPane>

            {activeTab === '2' && auth?.user?.accountType === 'admin' && (
                <TabPane tabId="2">
                  <Form onSubmit={(e) => handleAuth(e, true)}>
                    <div className="row">
                      {/* General Information Section */}
                      <div className="col-md-4">
                        <h5>Général</h5>
                        <FormGroup className="form-group">
                          <Label for="code" className="form-label">Numéro de compte</Label>
                          <Input required={true} type="text" name="code" id="code" placeholder="Entrez le numéro de compte" className="form-input" />
                        </FormGroup>
                        <FormGroup className="form-group">
                          <Label  for="name" className="form-label">Nom de client</Label>
                          <Input required={true} type="text" name="name" id="name" placeholder="Entrez le nom de client" className="form-input" />
                        </FormGroup>
                        <FormGroup className="form-group">
                          <Label for="email" className="form-label">Courriel</Label>
                          <Input required={true} type="email" name="email" id="email" placeholder="Entrez le courriel" className="form-input" />
                        </FormGroup>
                        <FormGroup className="form-group">
                          <Label for="accountType" className="form-label">Type de compte</Label>
                          <Input  required={true} type="select" name="accountType">
                            <option value="">Type de compte</option>
                            <option value="user">User</option>
                            <option value="admin">Admin</option>
                          </Input>
                        </FormGroup>
                        <FormGroup className="form-group">
                          <Label for="password" className="form-label">Mot de passe</Label>
                          <Input required={true} type="password" name="password" id="password" placeholder="Entrez un mot de passe" className="form-input" />
                        </FormGroup>
                        <FormGroup className="form-group">
                          <Label for="address" className="form-label">Numéro et nom de rue</Label>
                          <Input type="text" name="address" id="address" placeholder="Entrez l'adresse" className="form-input" />
                        </FormGroup>
                        <FormGroup className="form-group">
                          <Label for="accountingEmail" className="form-label">Courriel de comptabilité</Label>
                          <Input type="email" name="accountingEmail" id="accountingEmail" placeholder="Entrez le courriel de comptabilité" className="form-input" />
                        </FormGroup>
                        <FormGroup className="form-group">
                          <Label for="orderEmail" className="form-label">Courriel de commande</Label>
                          <Input type="email" name="orderEmail" id="orderEmail" placeholder={"Entrez le courriel de commande"} className="form-input" />
                        </FormGroup>
                        <FormGroup className="form-group">
                          <Label for="contactPerson" className="form-label">Personne à contacter</Label>
                          <Input type="text" name="contactPerson" id="contactPerson" placeholder="Entrez le nom de la personne à contacter" className="form-input" />
                        </FormGroup>
                        <FormGroup className="form-group">
                          <Label for="clientGroup" className="form-label">Groupe de clients</Label>
                          <Input type="text" name="clientGroup" id="clientGroup" placeholder="Entrez le groupe de clients" className="form-input" />
                        </FormGroup>
                      </div>

                      {/* Invoice Section */}
                      <div className="col-md-4">
                        <h5>Facturation</h5>
                        <FormGroup className="form-group">
                          <Label for="invoiceAddress" className="form-label">Numéro et nom de rue</Label>
                          <Input type="text" name="invoiceAddress" id="invoiceAddress" placeholder="Entrez l'adresse" className="form-input" />
                        </FormGroup>
                        <FormGroup className="form-group">
                          <Label for="invoicePostalCode" className="form-label">Code postal</Label>
                          <Input type="text" name="invoicePostalCode" id="invoicePostalCode" placeholder="Entrez le code postal" className="form-input" />
                        </FormGroup>
                        <FormGroup className="form-group">
                          <Label for="invoiceCity" className="form-label">Ville</Label>
                          <Input type="text" name="invoiceCity" id="invoiceCity" placeholder="Entrez la ville" className="form-input" />
                        </FormGroup>
                        <FormGroup className="form-group">
                          <Label for="invoiceProvince" className="form-label">Province</Label>
                          <Input type="text" name="invoiceProvince" id="invoiceProvince" placeholder="Entrez la province" className="form-input" />
                        </FormGroup>
                        <FormGroup className="form-group">
                          <Label for="invoicePhone" className="form-label">Téléphone</Label>
                          <Input type="text" name="invoicePhone" id="invoicePhone" placeholder="Entrez le téléphone" className="form-input" />
                        </FormGroup>
                        <FormGroup className="form-group">
                          <Label for="invoicePhone2" className="form-label">Téléphone 2</Label>
                          <Input type="text" name="invoicePhone2" id="invoicePhone2" placeholder="Entrez le téléphone 2" className="form-input" />
                        </FormGroup>
                        <FormGroup className="form-group">
                          <Label for="invoiceFax" className="form-label">Télécopieur</Label>
                          <Input type="text" name="invoiceFax" id="invoiceFax" placeholder="Entrez le télécopieur" className="form-input" />
                        </FormGroup>
                      </div>

                      {/* Expedition Section */}
                      <div className="col-md-4">
                        <h5>Expédition</h5>
                        <FormGroup className="form-group">
                          <Label for="expeditionAddress" className="form-label">Numéro et nom de rue</Label>
                          <Input type="text" name="expeditionAddress" id="expeditionAddress" placeholder="Entrez l'adresse" className="form-input" />
                        </FormGroup>
                        <FormGroup className="form-group">
                          <Label for="expeditionPostalCode" className="form-label">Code postal</Label>
                          <Input type="text" name="expeditionPostalCode" id="expeditionPostalCode" placeholder="Entrez le code postal" className="form-input" />
                        </FormGroup>
                        <FormGroup className="form-group">
                          <Label for="expeditionCity" className="form-label">Ville</Label>
                          <Input type="text" name="expeditionCity" id="expeditionCity" placeholder="Entrez la ville" className="form-input" />
                        </FormGroup>
                        <FormGroup className="form-group">
                          <Label for="expeditionProvince" className="form-label">Province</Label>
                          <Input type="text" name="expeditionProvince" id="expeditionProvince" placeholder="Entrez la province" className="form-input" />
                        </FormGroup>
                        <FormGroup className="form-group">
                          <Label for="expeditionPhone" className="form-label">Téléphone</Label>
                          <Input type="text" name="expeditionPhone" id="expeditionPhone" placeholder="Entrez le téléphone" className="form-input" />
                        </FormGroup>
                        <FormGroup className="form-group">
                          <Label for="expeditionPhone2" className="form-label">Téléphone 2</Label>
                          <Input type="text" name="expeditionPhone2" id="expeditionPhone2" placeholder="Entrez le téléphone 2" className="form-input" />
                        </FormGroup>
                        <FormGroup className="form-group">
                          <Label for="expeditionFax" className="form-label">Télécopieur</Label>
                          <Input type="text" name="expeditionFax" id="expeditionFax" placeholder="Entrez le télécopieur" className="form-input" />
                        </FormGroup>
                      </div>
                    </div>
                    <Button type="submit" className="form-button mt-3">Créer</Button>
                  </Form>
                </TabPane>
            )}
          </TabContent>
        </ModalBody>
      </Modal>
  );
}

export default ModalAuth;

